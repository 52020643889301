<template>
  <b-sidebar
    :visible="visible"
    right
    no-header
    backdrop
    width="400px"
    body-class="overflow-hidden"
    class="customizer"
    style="z-index: 1040 !important;"
    @change="handleSidebarChange"
  >
    <!-- Header -->
    <div class="customizer-section d-flex justify-content-between align-items-center">
      <div>
        <h4 class="text-uppercase mb-0">
          {{ $t('user-menu.preferences') }}
        </h4>
      </div>
      <feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer"
        @click="$emit('change', false)"
      />
    </div>

    <!-- Header -->
    <app-scroll
      :options="{ scrollPanel: { scrollingX: false } }"
      class="ps-customizer-area scroll-area"
    >
      <!-- Language -->
      <div class="customizer-section">
        <locale :display-mode="'checkbox'" />
      </div>

      <!-- Skin -->
      <!-- <div v-if="collective.isStaff" class="customizer-section">
        <b-form-group label="Skin">
          <b-form-radio-group
            id="skin-radio-group"
            v-model="skin"
            name="skin"
            :options="skinOptions"
            stacked
            class="my-1"
          />
        </b-form-group>
      </div> -->
    </app-scroll>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BFormRadioGroup,
  BFormGroup,
} from 'bootstrap-vue';
import AppScroll from '@core/components/app-scroll/AppScroll.vue';
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue';
import useAppCustomizer from './useAppCustomizer';

export default {
  components: {
    // BSV
    BSidebar,
    BFormRadioGroup,
    BFormGroup,
    Locale,
    // 3rd party
    AppScroll,
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  inject: ['$chatBot'],
  props: {
    visible: Boolean,
  },
  computed: {
    collective() {
      return this.$store.state.collective.collective;
    },
  },
  methods: {
    handleSidebarChange(isVisible) {
      this.$emit('change', isVisible);
      if (isVisible) {
        this.$chatBot.hide();
      } else {
        this.$chatBot.show();
      }
    },
  },
  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,
      // Skin
      skin,
      skinOptions,
      // Content Width
      contentWidth,
      contentWidthOptions,
      // RTL
      isRTL,
      // routerTransition
      routerTransition,
      routerTransitionOptions,
      // Layout Type
      layoutType,
      layoutTypeOptions,
      // NavMenu Hidden
      isNavMenuHidden,
      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,
      // Footer
      footerTypes,
      footerType,
    } = useAppCustomizer();
    if (layoutType.value === 'horizontal') {
      // Remove semi-dark skin option in horizontal layout
      const skinSemiDarkIndex = skinOptions.findIndex((s) => s.value === 'semi-dark');
      delete skinOptions[skinSemiDarkIndex];
      // Remove menu hidden radio in horizontal layout => As we already have switch for it
      const menuHiddneIndex = navbarTypes.findIndex((t) => t.value === 'hidden');
      delete navbarTypes[menuHiddneIndex];
    }
    return {
      // Vertical Menu
      isVerticalMenuCollapsed,
      // Skin
      skin,
      skinOptions,
      // Content Width
      contentWidth,
      contentWidthOptions,
      // RTL
      isRTL,
      // routerTransition
      routerTransition,
      routerTransitionOptions,
      // Layout Type
      layoutType,
      layoutTypeOptions,
      // NavMenu Hidden
      isNavMenuHidden,
      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,
      // Footer
      footerTypes,
      footerType,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include';
@import '~@core/scss/base/components/variables-dark';
.customizer-section {
  padding: 1.5rem;
    border-bottom: 1px solid #ebe9f1;
  .dark-layout & {
    border-color: $theme-dark-border-color;
  }
  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }
  .form-group {
    margin-bottom: 1.5rem;
    &:last-of-type {
    margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}
.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}
.ps-customizer-area {
  height: calc(100% - 83px)
}
</style>
